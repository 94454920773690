.RSPBprogressBar {
    height: 36px;
    line-height: 1;
    border-radius: 20px;
    position: relative;
    background-color: rgba(211, 211, 211, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0; }
    .RSPBprogressBar .RSPBstep {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
      position: absolute;
      transform: translateX(-50%);
      transition-property: all;
      transition-timing-function: ease; }
    .RSPBprogressBar .RSPBprogressBarText {
      color: white;
      font-size: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .RSPBprogressBar .RSPBprogression {
      height: 30px;
      position: absolute;
      transition: width 0.3s ease;
      left: 3px;
      top: 3px;
      bottom: 0;
      border-radius: 20px;
      background: rgba(0, 116, 217, 0.8);
      z-index: -1; 
      clip-path: polygon(0% 0%, 100% 0%, 98% 98%, 0% 100%);

    }
  
  @media (max-width: 900px) {
    .RSPBprogressBar {
      height: 26px;
    }

    .RSPBprogressBar .RSPBprogression {
      height: 20px;
    }
  }