.reveal-container {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    display: inline-block;
    white-space: nowrap;
    background: #000000;
    overflow: hidden;
}

  .no-reveal-container {
    /* height: calc(100vh - 82px); */
    height: calc(100vh - 82px);
    width: 100vw;
    max-width: 100vw;
    position: relative;
    top: 0;
    display: inline-block;
    white-space: nowrap;
    background: #000000;
  }

  .mobile-no-reveal-container {
    display: none;
  }

.reveal-menu {
    position: fixed;
    top: calc(100vh - 100px);
    left: 50%;
    transform: translateX(-50%);
    background: rgb(0, 0, 0, 0.5);
    padding: 0.5em 1em;
    border-radius: 10px;
}

.left, .middle, .right {
    display: inline-block;
    overflow: hidden;
    position: relative;
}

.left, .middle, .right {
    position: absolute;
    top: 0;
    width: 100vh;
    height: 100vh;
    min-width: 100vh;
    min-height: 100vh;
}

.left-no-reveal, .middle-no-reveal, .right-no-reveal {
  display: inline-block;
  overflow: hidden;
  position: relative;
  /* top: 0; */
  height: 100%;
  width: 33.333%;
}

.left-no-reveal, .right-no-reveal {
  background: #0F0F0F;
}

.left {
    display: block;
    overflow: hidden;
    left: 0%;
    height: 100%;
    transition: left 0.15s, background-position-x 0.15s;
}

.middle {
    left: 25%;
    height: 100%;
    background-position-x: 0;
    background-size: cover;
    background-repeat: no-repeat;
    transition: left 0.15s, background-position-x 0.15s;
}

#middleImageContainer {
  position: relative;
}

#middleImage {
  position: absolute;
  height: 100%;
  max-height: 100%;
  width: 100%;
}

.right {
    left: 75%;
    height: 100%;
    transition: left 0.15s, background-position-x 0.15s;
}

.left video, .right video {
    object-position: -250px 50%;
    transition: left 0.15s, object-position 0.15s;
}

#leftVideoContainer {
    position: relative;
    width: 100%;
}

.overlay::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }
  
  .loading-overlay::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }

  .loading-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
  }

.loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(rgba(16, 16, 16, 1), rgba(0, 0, 0, 1));
}

.loading-klem-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

video {
    /* override other styles to make responsive */
    width: 100% !important;
    height: 100% !important;
}

.reveal-page .menu .white-nav-bar {
  background-color: #FFFFFF !important;
}

.mobile-reveal-container {
    width: 100vw;
    height: 100vh;
    /* position: relative; */
    display: none;
}

.swiper {
    width: 100%;
    height: 70%;
}
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

#firstSlide {
    width: 100%;
}

#secondSlide {
    width: 80%;
}

#thirdSlide {
    width: 100%;
}

#secondSlide img {
    border-radius: 27px;
    overflow: hidden;
}

#firstSlide video, #thirdSlide video {
    border-radius: 27px;
    overflow: hidden;
}


.smooth-image {
    transition: opacity 1s;
}

.image-visible {
    opacity: 1;
}

.image-hidden {
    opacity: 0;
}

.reveal-1{
    background: #5FBCDC
  }
  /* .reveal-2{
    background: #5FBCDC
  }
  .reveal-3{
    background: #0F1D3B
  } */
  .slider-item{
    height: 64vh;
    width: 75vw;
  }
  .slick-track{
    display: flex!important;
      align-items: center;
  }
  .slider-video{
    border-radius: 20px;
    filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.25));
  }
  .slider-container{
    padding: 10px;
  }
  .slider-item-image{
    height: 100%;
    background-size: cover;
      background-position: center;
      border-radius: 20px;
      filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.25));
  }
  /* .slick-slide{
    height: 84vh;
  } */
  .slick-active .slider-item{
    height: 75vh;
    /* width: 75vw; */
  
  }
  .button-next-div{
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
  }
  .button-next{
    font-family: "Quirky Sunflower";
    background: #FFFFFF;
    width: fit-content;
    padding: 10px;
    border:0px;
    border-radius: 13.0768px;
    box-shadow: -2px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .button-locked {
    font-family: "Quirky Sunflower";
    background: #212121;
    color: #FFFFFF;
    width: fit-content;
    padding: 10px 16px;
    border:0px;
    border-radius: 13.0768px;
    font-size: 1.25rem;
  }

  .button-locked img {
    padding-right: 10px;
    height: 18px;
  }


/* tablets portrait */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)
and (orientation : portrait) { 
  .reveal-container,
  .no-reveal-container {
      display: none;
  }
  .mobile-reveal-container,
  .mobile-no-reveal-container {
      position: relative;
      padding-top: 100px;
      height: 110vh;
      display: block;
  }
}

@media (max-width: 600px) {
    .reveal-container,
    .no-reveal-container {
        display: none;
    }
    .mobile-reveal-container,
    .mobile-no-reveal-container {
        position: relative;
        padding-top: 100px;
        height: 110vh;
        display: block;
    }

    .mobile-no-reveal-container {
      background: #000000;
    }

    .slider-item-empty {
      background: #212121;
      border-radius: 20px;
    }

    .no-reveal-text {
      color: #FFFFFF;
      font-size: 8rem;
    }
}

@media (max-height: 680px) {
    .middle {
      /* background-position-x: 0 !important; */
    }
}