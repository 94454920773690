html,
body {
  margin: 0px;
  padding: 0px;
  /* height: 100vh !important; */
}

body {
  /* background: #009251 !important; */
  /* background: transparent !important; */
  background-image: url(./assets/bg-blob.png);
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center 600px;
  /* z-index: -1; */
  width: 100%;
  background-color: #009251 !important;
}

.generic-bg {
  /* background-image: url(./assets/bg-blob.png);
  min-height: 100%;
  position: fixed;
  background-repeat: no-repeat;
  background-size: 80% 80%;
  background-position: center 600px;
  z-index: -1;
  width: 100%; */
  background-color: #009251;
}
#root {
  /* height: 100%; */

  /* height: 100vh; */  
}
#root > div {
  /* background: #009251; */
}

.laboog-art {
  background-image: url(./assets/laboog-bg-3.png);
  height: 100%;
  position: absolute;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.into-laboog-header{
  justify-content: center;
  display: flex;

}
.into-the-laboog-2,
.into-the-laboog-mobile {
  width: 75%;
}
.into-the-laboog-2{
  width: 65%;
}
.into-the-laboog-mobile {
  display: none;
}
.mobile-nav {
  display: none;
}
.logo-mobile-full {
  display: none;
}
.character-modal-div,.wtf-laboog-modal {
  background-color: white;
  border-radius: 16px;
  overflow: overlay;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-filter: blur(0.000001px);
  padding: 30px;
}
.wtf-laboog-modal{
  font-family: "Quirky Sunflower";
  text-align: center;
  font-size: 20px;
  width: 50vw;
  height: 90vh;
  padding: 10px;
  outline: none;
}
.auction-div{
  display: flex;
  justify-content: center;
}

.auction1-box, 
.auction2-box, 
.auction3-box, 
.auction4-box, 
.auction5-box, 
.auction6-box, 
.auction7-box {
  z-index: 3;
} 

.auction1-box{
  top: 150px;
}
.auction2-box{
  top: 0;
}
.auction3-box{
  top: 110px
}
.auction4-box{
  top: 525px
}
.auction5-box{
  top: 400px
}
.auction6-box{
  top: 440px;
  left: 90x;
}
.auction7-box{
  top: 700px;
  /* left: 160px; */
}

.auction8-box{
  top: 700px;
  /* left: 160px; */
}

.auction9-box{
  top: 700px;
  /* left: 160px; */
}

.auction10-box{
  top: 950px;
  /* left: 160px; */
}

.auction11-box{
  top: 950px;
  /* left: 160px; */
}

.auction12-box{
  top: 950px;
  /* left: 160px; */
}

.auction-div>div{
  display: flex;
  justify-content: center;
  border-radius: 40px;
  /* position: absolute; */
}
.wtf-laboog-modal-title{
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 80px;
  line-height: 80px;
  font-weight: 160;
  font-family: "Tiny Font";
}
.wtf-green-black-div-text.desktop{
  display: block;
}
.wtf-green-black-div-text.mobile{
  display: none;
}
.wtf-green-text{
  color: #9FA94C;
}
.wtf-blue-text{
  color: #85BCF0;
}
.wtf-green-black-div-text,.wtf-green-black-div-text-alt{
  background-color: #000000;
  color:#FFDD54;
  width: fit-content;
  padding: 0 4px 0 4px;
  margin-right: 0px;
}
.wtf-green-black-div-text-alt{
  font-family: "ShadowsIntoLight";
}
.wtf-modal-div,.wtf-modal-div-alt,.wtf-modal-info-div{
  display: flex;
  align-items: center;
}
.wtf-modal-info-div{
  margin-top:20px;
  background: rgba(0, 0, 0, 0.03);
  gap: 30px;
  padding: 20px;
  border-radius: 24px;
}
.wtf-modal-info-content{
  font-size: 16px;
  margin-top: 10px;
}
.wtf-laboog-modal{
  overflow: hidden;
}
.wtf-laboog-modal-container{
 overflow: scroll;
 padding:15px;
 height: 100%;
 overflow-x: hidden;
}
.wtf-laboog-modal-container::-webkit-scrollbar {
  width: 8.13px;
  background: #1E1E1E;
  border-radius: 10px;
}

/* Track */
.wtf-laboog-modal-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
 margin-left: 10px;
}
 
/* Handle */
.wtf-laboog-modal-container::-webkit-scrollbar-thumb {
    background: #1E1E1E;
    border-radius: 10px;
}

/* Handle on hover */
.wtf-laboog-modal-container::-webkit-scrollbar-thumb:hover {
    background: #1E1E1E;
    
    border-radius: 10px;
}
.wtf-step-title{
  line-height: normal;
  font-family: "Tiny Font";
  font-size: 40px;
  margin-bottom: 10px;
}

.wtf-step-title-a{
  text-decoration: underline;
}
.wtf-modal-div-container{
  background: rgba(58, 65, 11, 0.03);
  border-radius: 28px;
}
.wtf-modal-div-alt{
  font-weight: 400;
font-size: 24px;
  text-align: left;
}
.wtf-laboog-img-1{
width: 221px;
height: 221px;
}
.wtf-link{
  text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000,
  1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000;
color: #ffffff;
text-decoration: none;
}
.wtf-modal-info-img{
  width: 25%;
  height: auto;
}
.wtf-modal-div-text-1{
  padding-right: 30px;
  text-align: left;
}
.wtf-modal-div-text-2{
  padding-left: 30px;
  text-align: left;
}
.character-modal-btn {
  font-family: "Space Grotesk";
  padding: 2px 16px;
  gap: 16px;
  width: 196px;
  height: 40px;
  color: white;
  background: #000000;
  border-radius: 43px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}
.wtf-button{
  display: flex;
  padding: 10px 16px;
  gap: 10px;
  position: absolute;
  right: 20%;
  top: 70%;
  bottom: -56px;
  color: white;
  font-weight: 400;
  font-size: 24px;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  height: 52px;
  background: #101010;
  box-shadow: -6px 8px 0px #8FA4D7;
  font-family: "Quirky Sunflower";
  border-radius: 16px;
  z-index: 100;
  transform: rotate(-7.57deg);
}
.wtf-button:hover{
  background: #FFFFFF;
  border: 0px;
  color: black;
}
.character-modal-btn >img{
  width: 11px;
}
.character-modal-header {
  font-size: 28px;
  line-height: 120%;
  text-align: center;
  margin-bottom: 24px;
}

.voting-bg {
  background-image: url(./assets/booger-vote-bg.png);
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}




.feed-bg {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/coc-feed-vector.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
}

.vote-booger {
  position: absolute;
  right: 0;
  top: 150px;
}

.vote-container {
  top: 100px;
  position: relative;
  max-width: 100% !important;
}

.mobile-vote-header-container {
  display: none !important;
}

.vote-character-box {
  top: 644px;
  left: 240px;
  position: absolute;
}

.vote-character-box .wtf-happening {
  top: 320px;
  left: 140px;
  position: absolute;
}

.total-votes-box {
  border: 3px dashed #DA884E;
  border-radius: 20px;
  padding: 0.25em 0.75em;
  margin-right: 1%;
}

.auction-button-container {
  overflow: hidden;
  width: 320px;
  height: 260px;
  border-radius: 40px;
  background:#0ACC76;
}


.auction-button {
  background-position: left top;
  background-repeat: no-repeat;
  /* width: 90%; */
  height: 90%;
  border-radius: 40px;
}

.download-text {
  position: absolute;
  bottom: 14px;
  visibility: hidden;
}

.download-text a {
  font-size: 1.5rem;
  text-decoration: none;
  line-height: 1.5;
  color: #000;
  background: #FFF;
  border: 2px solid #000;
  border-radius: 40px;
  box-shadow: 2px 4px #000;
  padding: 0.25em 0.75em;
  display: block;
}

.auction-button-container:hover .download-text {
  visibility: visible;
  transition: 0.2s;
}

.auction1-container {
  transform: rotate(-2deg); 
  position: relative;
}

.auction1-container:hover,
.auction2-container:hover,
.auction3-container:hover,
.auction4-container:hover,
.auction5-container:hover,
.auction6-container:hover,
.auction7-container:hover,
.auction8-container:hover,
.auction9-container:hover,
.auction10-container:hover,
.auction11-container:hover,
.auction12-container:hover {
  transform: scale(1.1);
  transition: 0.2s;
}

.auction2-container {
  transform: rotate(2deg);
  position: relative;
}


.auction3-container {
  transform: rotate(-2deg); 
  position: relative;
}

.auction4-container {
  transform: rotate(-2deg); 
  position: relative;
}

.auction5-container {
  position: relative;
  transform: rotate(-2deg); 
}

.auction6-container {
  position: relative;
  transform: rotate(2deg); 
}

.auction7-container {
  position: relative;
  transform: rotate(-2deg); 
}

.auction8-container {
  position: relative;
  transform: rotate(2deg); 
}

.auction9-container {
  position: relative;
  transform: rotate(2deg); 
}

.auction10-container {
  position: relative;
  transform: rotate(-2deg); 
}

.auction11-container {
  position: relative;
  transform: rotate(-2deg); 
}

.auction12-container {
  position: relative;
  transform: rotate(2deg); 
}

.loading {
  background-color: #ffffff;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 10;
  display: block;
  /* bottom: 0px; */
}

.loaded {
  display: none;
}

.loading-feed {
  position: absolute;
  left: -9999px;
}

.loaded-feed {
}

.loader-container {
  height: calc(100% - 69px);
}

.loader-container img {
  animation: rotation 3s infinite linear;
}
.TwitterTweetEmbed{
  width: 98%;
  border-radius: 20px;
}
.TwitterTweetEmbed-div{
  justify-content: space-evenly;
  display: flex;
}
.TwitterTweetEmbed-container{
padding: 30px 0;
position: relative;
/* top: 100px; */
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

h1 {
  color: #000;
  font-family: "Quirky Sunflower";
  font-size: 4rem;
  /* transform: rotate(-5deg); */
  margin-top: 60px;
  margin-bottom: 60px;
  display: inline-block;
}

h2 {
  color: #000;
  font-family: "Tiny Font";
  font-size: 2.2rem;
  font-weight: 400;
}

h3 {
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  font-size: 7rem;
  margin: 0;
  text-shadow: 6px 8px #000 !important;
}

.header3 {
  color: #fff;
  -webkit-text-stroke-width: 1px !important;
  -webkit-text-stroke-color: black;
  font-size: 7rem !important;
  margin: 0;
  text-shadow: 6px 8px #000 !important;
  font-weight: 700 !important;
}

h4 {
  text-shadow: 2px 2px #000 !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  display: block;
  transition: color 0.5s;
  font-size: 1.4rem;
  line-height: 1.1;
}

h5 {
  color: #101010;
  font-family: "Space Grotesk";
  font-size: 2.2rem;
  font-weight: 400;
}

.logo-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  position: absolute;
  padding: 1.675em 1.5em;
}
.logo-followTwitter{
 height: 25px;
 width: auto;
 cursor: pointer;
}
.logo-container:hover {
  cursor: pointer;
}

.logo-container-mobile {
  margin-top: 8px;
}
.menu ul {
  background-color: #009251;
  /* border-bottom: 1px solid #f3f3f3; */
  margin: 0;
  list-style: none;
  padding: 1.25em 1.5em;
  display: flex;
  justify-content: end;
  gap: 1em;
  z-index: 5;
}

.coc-feed-vector {
  display: block;
  /* position: absolute; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/coc-feed-vector.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 80%;
}

.coc-leader-vector {
  display: block;
  /* position: absolute; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/coc-leader-vector.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 90%;
}

.coc-assets-vector {
  display: block;
  /* position: absolute; */
  left: 0;
  /* top: 0; */
  width: 100%;
  height: 100%;
  background-image: url("./assets/coc-assets-vector.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 70%;
  /* top: 80px; */
}

.coc-profile-vector {
  display: block;
  /* position: absolute; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/coc-profile-vector.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 90%;
}

.reveal-page {
  z-index: 10;
  position: sticky;
}

.reveal-page .menu ul,
.voting-bg .menu ul {
  background-color: transparent !important;
  border-bottom: 0;
}

.voting-bg .menu ul {
  border-bottom: 1px solid #f3f3f3;
}

.dont-forget-box {
  position: relative;
  left: 120px;   
  top: 50px;
}

.laboog-art .menu ul {
  border-bottom: 0;
}
.laboog-art .menu {
  z-index: 100;
  position: sticky;
}
.laboog-art .logo-container {
  z-index: 101;
}

.menu li {
  color: #101010;
  font-size: 1.4rem;
  line-height: 1.1;
  /* height: 40px; */
  border-radius: 12px;
  padding: 0.3em 0.5em;
  display: flex;
  align-items: center;
}

.menu li:last-of-type {
  padding-top: 0;
  padding-bottom: 0;
}

.menu li.wallet {
  border: 0;
  padding: 0;
}

.menu a:not(.navbar-twitter) {
  text-shadow: 2px 2px #000 !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  display: block;
  transition: color 0.5s;
  font-size: 1.8rem;
}
.menu a.active {
  /* text-shadow: rgb(153, 126, 126) 5px 0px 0px, rgb(0, 0, 0) 4.90033px 0.993347px 0px, rgb(0, 0, 0) 4.60531px 1.94709px 0px, rgb(0, 0, 0) 4.12668px 2.82321px 0px, rgb(0, 0, 0) 3.48353px 3.58678px 0px, rgb(0, 0, 0) 2.70151px 4.20736px 0px, rgb(0, 0, 0) 1.81179px 4.6602px 0px, rgb(0, 0, 0) 0.849836px 4.92725px 0px, rgb(0, 0, 0) -0.145998px 4.99787px 0px, rgb(0, 0, 0) -1.13601px 4.86924px 0px, rgb(0, 0, 0) -2.08073px 4.54649px 0px, rgb(0, 0, 0) -2.94251px 4.04248px 0px, rgb(0, 0, 0) -3.68697px 3.37732px 0px, rgb(0, 0, 0) -4.28444px 2.57751px 0px, rgb(0, 0, 0) -4.71111px 1.67494px 0px, rgb(0, 0, 0) -4.94996px 0.7056px 0px, rgb(0, 0, 0) -4.99147px -0.291871px 0px, rgb(0, 0, 0) -4.83399px -1.27771px 0px, rgb(0, 0, 0) -4.48379px -2.2126px 0px, rgb(0, 0, 0) -3.95484px -3.05929px 0px, rgb(0, 0, 0) -3.26822px -3.78401px 0px, rgb(0, 0, 0) -2.4513px -4.35788px 0px, rgb(0, 0, 0) -1.53666px -4.75801px 0px, rgb(0, 0, 0) -0.560763px -4.96845px 0px, rgb(0, 0, 0) 0.437495px -4.98082px 0px, rgb(0, 0, 0) 1.41831px -4.79462px 0px, rgb(0, 0, 0) 2.34258px -4.41727px 0px, rgb(0, 0, 0) 3.17346px -3.86382px 0px, rgb(0, 0, 0) 3.87783px -3.15633px 0px, rgb(0, 0, 0) 4.4276px -2.32301px 0px, rgb(0, 0, 0) 4.80085px -1.39708px 0px, rgb(0, 0, 0) 4.98271px -0.415447px 0px; */
    /* -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black; */
  color: #ffffff;
}

.menu a.navbar-twitter {
  text-decoration: none;
}

.follow-text {
  text-shadow: 2px 2px #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  display: block;
  transition: color 0.5s;
}

.pfp-image {
  border-radius: 50%;
}

.tbl-scroll {
  height: 610px;
  border: 0;
  overflow-x: visible !important;

}

.tbl-scroll::-webkit-scrollbar {
  width: 0.5em;
  left: -100px;
}

.tbl-scroll::-webkit-scrollbar-track {
  background-color: #f3f3f3;
  border-radius: 10px;
}

.tbl-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #F00600;

  /* background-color: #cd615d; */
}

.leader-username {
  padding-left: 8px;
  color: #5FBF7E;
}

.memes-banner {
  padding-left: 6px;
}

.profile-box {
  width: 100%;
  height: 197px;
  border-radius: 20px;
  position: relative;
}

.profile-container {
  position: relative;
  background: #FFF;
  border: 2px solid #000;
  width: 500px;
  padding: 10px;
  box-shadow: 6px 8px #000;
  border-radius: 20px;
}

.level-box {
  background: #FFF;
  border: 2px solid #000;
  border-radius: 30px;
  height: 50px;
  box-shadow: 4px 6px #000;
  z-index: 2;
  display: block;
  overflow: hidden;
  position: relative;
}

.level-box img {
  margin-top: 2px;
  z-index: 1;
}

.level-bar-container {
  background: #F00600;
  position: relative;
}

.level-text {
  color: #FFD400;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  font-size: 20px !important;
  text-shadow: 1px 2px #000 !important;
}

.level-empty {
  background: white;
  z-index: 3;
  height: 50px;
  top: 0;
  right: 0;
  position: absolute;
  overflow:  hidden;
}

.level-name-text {
  color: #FFF;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  font-size: 28px !important;
  text-shadow: 1px 2px #000 !important;
  
}

.mobile-profile-container {
  display: none !important;
}

.mobile-navbar>a,.mobile-navbar>#connectedButtonContainer{
  width: fit-content;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 90px;
  padding-left: 8px;
  justify-content: center;  
}

.profile-box:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-image: url("./assets/profile-decoration.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
}

.profile-box.blue {
  background-color: #b4c6fd;
}

.profile-box.red {
  background-color: #ed9891;
}

.profile-box.yellow {
  background-color: #f5f1ac;
}

.profile-box.orange {
  background-color: #e0a482;
}

.profile-box.green {
  background-color: #bdd070;
}

.MuiBreadcrumbs-separator {
  font-size: 35px;
  color: #ed9891;
  font-family: "Tiny Font";
}

.MuiTableRow-hover {
  cursor: pointer;
}
.character-container {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
}

.booger-characters-container {
  display: flex;
  overflow: auto;
  align-items: end;
}
.booger-character-div {
  align-items: baseline;
  height: fit-content;
  padding-top: 60px;
  display: flex;
  bottom: 0;
}
.booger-character-div-1{
  margin-left: 70px;
}
.booger-character-select {
  display: none;
}
.booger-character-select-div {
  position: relative;
}
.booger-character-div:hover > div > img {
  cursor: pointer;
  z-index: 100;
  filter: drop-shadow(-10px 0px 0px #ffffff);
}

.booger-character-div:hover > div > .booger-character-select {
  display: block;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 2px 2px 12px;
  gap: 8px;
  position: absolute;
  width: max-content;
  height: 42px;
  z-index: 100;
  border-radius: 43px;
  margin-left: -45px;
  margin-top: -67px;
}
.booger-character-div > div > .booger-character-select-12 {
  margin-left: 45px;
}

.booger-character-div > div > .booger-character-select > img {
  width: 100%;
  height: 46px;
}
.booger-characters-container {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}
.booger-characters-container::-webkit-scrollbar {
  display: none;
}
.booger-characters-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.booger-characters-container::-webkit-scrollbar-thumb {
  background: black; 
  border-radius: 10px;
}

/* Handle on hover */
.booger-characters-container::-webkit-scrollbar-thumb:hover {
  background:black; 
}
.character-select-div{
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 10px;
}
.booger-chracter-selector{
  width: 99px;
  height: 99px;
  border-radius: 12px;
}

.booger-character {
  height: auto;
  max-width: 93px;
  margin-left: 10px;
}
.booger-character-6 {
  max-width: 112px;
}
.booger-character-7 {
  max-width: 150px;
}
.booger-character-8 {
  max-width: 160px;
  margin-left: -30px;
}
.booger-character-10 {
  max-width: 151px;
  margin-left: -10px;
}
.booger-character-12 {
  max-width: 291px;
}
.booger-character-13 {
  max-width: 150px;
}
.booger-character-14 {
  max-width: 200px;
  margin-right: 70px;
}
.character-box {
  padding-top: 30px;
  margin-left: -16px;
  bottom: 0px;
  display: flex;
  width: 100%;
  position: fixed;
  z-index: 99;
  cursor: pointer;
}
.logo-mobile-close {
  display: none;
}
.mobile-div {
  font-size: 32px;
  line-height: 43px;
  letter-spacing: 0.01em;
  list-style: none;
  padding-left: 0px;
}
.mobile-div > li > a {
  text-decoration: none;
  color: #000000;
}
.mobile-div > li > a:hover {
  text-decoration: none;
  color: white;
}
.mobile-div > li > a.active {
  text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000,
    1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000;
  color: #ffffff;
}
.mobile-div > li {
  text-align: center;
  margin-bottom: 20px;
}
.loadingPage{
  z-index: 103;
  position: fixed;
  height: 100%;
  background-color: #EDB087;
  width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
}
.modal-close-btn{
  margin-left: 90%;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.loading-animation{
  height: 300px;
  width: 300px;
  z-index: 12;
  margin-left: -40px;
}
.reveal-step-div{
  margin-top: 24px;
}

.reveal-step.accomplished:hover,
.reveal-image.accomplished:hover {
  cursor: pointer;
}

.MuiTextField-root.text-field{
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 2px solid #000;
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 1);
  border-radius: 40px;
  margin-left: 8px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #F2F2F2;
  color: #101010;
  width: 30em;
  }
  .revealHeader{
    width: 350px;
    padding-bottom: 50px;
  }
  .TextField{
    margin-top: 32px;
    margin-left: 32px;
  }
  .postMemesMobile{
    display: none;
  }
  .modal-connect{
    width: 580px;
  }
  .sgwigly{
    width: 700px;
    z-index: 10;
    position: absolute;
  }
  .sgwigly-1{
    top: 0;
    left: 0;
  }
  .sgwigly-2{
    bottom: 0;
    left: 0;
  }
  .sgwigly-3{
    top: 0;
    right: 0;
    width: 200px;
    transform: rotate(180deg);
  }
  .sgwigly-4{
    right: 0;
    bottom: 0;
  }

  .desktop-leaderboard{
    display: block;
  }
  .mobile-leaderboard{
    display: none;
  }

  .mobile-boogerCharacter-leaderboard {
    display: none;
  }

  .feed-character {
    position: absolute; 
    left: 0; 
    top: 240px;
  }
  .youWinning-img{
    position: relative;
    left: 60px;
    width: 239px;
    height: 84px;
  }

.connected-container {
  position: relative;
}

.connected-container:hover {
  cursor: pointer;
}

.MuiPopover-paper {
  border-radius: 20px !important;
  overflow: hidden !important;
  margin-top: 10px;
}
.booger-mobile{
  display: none;
}
.booger-chracter-selector{
  background-color: #E6E6E6;
}
.vote-booger-600px{
  display: none;
}
@media (max-width:950px) {
  .pressButton{
    display: none;
  }

  .TwitterTweetEmbed-container{
    margin-top: 80px !important;
    }
}
@media (max-width:1100px) {
  .feed-character {
    top: 176px;
    left: -50px;
}
  .pressButton{
    display: none;
  }
.booger1{
 display: none;
}
.booger-mobile{
  display: block;
}
  .feed-container {
    /* position: relative;
    z-index: 100; */
    margin-top: 100px;
  }
 .leaderboard-container> .desktop-leaderboard{
    display: none;
  }
  .tabs-leaderboard div{
    justify-content: space-between;
    
  }
  .tabs-leaderboard button{
    font-size: 35px;
    font-family: "Tiny Font";
  }
 .tabs-leaderboard button.Mui-selected{
    color:#000000;
  }
  .MuiTabs-indicator{
    background-color: #000000;
  }
  .mobile-leaderboard{
    margin-top: 30px;
    display: block;
  }

  .tbl-scroll {
    height: auto;
    border: 0;
    overflow-x: visible !important;
  }

  .leader-profile {
    padding-left: 0 !important;
  }

  .leader-username {
    display: block;
    padding-left: 0;
  }
  
  .profile-header-table th:nth-of-type(3n),
  .profile-table td:first-of-type {
    padding-right: 0;
  }

  .profile-header-table th:last-of-type,
  .profile-table td:last-of-type  {
    /* display: none; */
  }

  .logo-container {
    display: none;
  }
  .wtf-laboog-modal{
    width: 87vw;
    height: 82vh;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 0%;
    top: 10%;
    border-radius: 36px;
  }
  .wtf-modal-div-container {
    padding: 20px;
}
.wtf-laboog-modal-title {
  margin-top: 10px;
  margin-bottom: 35px;
  font-size: 78px;
  letter-spacing: 0.01em;
  line-height: 80%;
  font-family: "Tiny Font";
}
.wtf-green-black-div-text.desktop{
  display: none;
}
.wtf-green-black-div-text.mobile{
  display: block;
}
.wtf-laboog-modal-title-alt {

  font-size: 72px;
}
.wtf-modal-info-img {
    width: 28%;
    height: auto;
}

  .wtf-laboog-modal-container::-webkit-scrollbar-track {
    background: white;
    border: 0px;
  }
  .wtf-laboog-modal-container::-webkit-scrollbar-thumb {
    border: 0px;
  }

  .wtf-laboog-modal-container::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 30px; 
    border: 0px;
}

.wtf-laboog-modal-container::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 30px;
    border: 0px;
}
  .laboog-art .menu ul {
    display: none;
  }
  .laboog-art .mobile-nav{
    background-color: white;
  }
  .menu ul {
    display: none;
  }
 
  .mobile-nav {
    display: block;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    top: 0px;
    z-index: 101;
    position: absolute;
    background: transparent !important;
  }
  .modal-close-btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .logo-mobile-full {
    display: block;
    position: fixed;
    justify-content: center;
    align-items: top;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #009251;
    z-index: 100001;
  }

  .logo-mobile {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .logo-mobile-nav,
  .logo-mobile-nav-close {
    cursor: pointer;
  }
  .logo-mobile-nav-close {
    margin-left: auto;
    display: flex;
    margin-right: 10px;
    margin-top: 10px;
  }
  .auction-div{
    display: flex;
    justify-content: center;
  }
  .auction1-box{
    top: 72px
  }
  .auction2-box{
    top: -45px
  }
  .auction3-box{
    top: 472px
  }
  /* .auction4-box{
    top: 441px;
  } */
  .auction5-box{
    top: 900px
  }
  .auction6-box{
    top: 900px
  }
  .auction7-box{
    top: 1300px;
    left:0px!important;
  }

  .auction8-box{
    top: 1300px;
  }
  .auction9-box{
    top: 1300px;
  }

  .dont-forget-box {
    left: 0;
  }

  .dont-forget-box img {
    width: 250px;
  }

  .vote-select img {
    width: 250px;
  }

  .vote-character-box {
    top: 1250px;
    left: 20px;
    position: absolute;
    /* bottom: 0; */
  }

  .vote-character-box .wtf-happening {
    top: 270px;
  }
  .vote-character-box > img {
    width: 150px;
  }
  .postMemesMobile {
    display: block;
    position: absolute;
    right: 0;
    height: 118.56px;
    transform: rotate(2.54deg);
    top: 215px;
}
  .postMemesMobile>img{
    width: 177.68px;
  }
  .postMemes{
    display: none;
  }
  .boogerCharacter-leaderboard{
    display: none;
  }

  .mobile-boogerCharacter-leaderboard{
    display: block;

  }

  .youWinning-img{
    position: inherit;
    left: -264px;
    top: 116px;
    margin-top: 155px;
  }
  .leaderboardHeader-img{
    left: 50%;
    position: absolute;
    transform: translate(-50%, 43%);
  }
}
@media (max-width:1440){
.wtf-laboog-modal{
  width: 75w;
}
}

@media (min-width: 600px) {
  .booger-character {
    /* height: 80%; */
    max-height: 33vh;
    max-width: 13vw;
    margin-left: 10px;
}
.dragIcon{
  position: absolute;
  right: 40px;
  bottom: 50px;
}
.character-box>.booger-characters-container {
  display: flex;
  overflow: auto;
  align-items: end;
  height: 100vh;
}
.booger-character-2{
  max-height: 40vh;
}
.booger-character-4{
  max-height: 40vh;
}
.booger-character-6 {
  max-width: 15vw;
}
.booger-character-7 {
  max-height: 36vh;
  max-width: 21vw;
}
.booger-character-8 {
  max-width: 25vw;
  max-height: 54vh;
  margin-left: -30px;
}
.booger-character-10 {
  max-height: 20vh;
  max-width: 22vw;
  margin-left: -10px;
}
.booger-character-12 {
  max-width: 45vw;
  max-height: 40vh;
}
.booger-character-13 {
  max-width: 45vw;
  max-height: 40vh;
}
.booger-character-14 {
  max-width: 52vw;
  max-height: 57vh;
}
}

.meme-count-mobile {
  display: none;
}




@media (max-width: 600px) {
 
  .vote-container {
    overflow-x: hidden;
    max-width: 99% !important;
  }

  .character-modal-div{
    width: 90vw;
    max-width: 370px;
    padding: 20px 13px;
  }
 
  .booger-chracter-selector {
    width: 30%;
    height: 30%;
    border-radius: 12px;
}
  .dragIcon{
    display: none;
  }
  .wtf-button{
    margin-top: 56px;

  }
  .wtf-modal-info-img {
    width: 60%;
    height: auto;
  }
  .into-the-laboog-mobile {
    display: block;
  }
  .into-the-laboog-2 {
    display: none;
  }
  .wtf-modal-div,.wtf-modal-info-div{
    display: block;
  }
  .laboog-art {
    background-image: url(./assets/laboog-bg-3-rotate.png);
  width: 100%;
  height: 2400px;
    /* position: fixed; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .mobile-text{
    font-size: 12px!important;
  }
 .character-box> .indiana-scroll-container--hide-scrollbars {
    overflow: inherit;
  }
  
  .revealHeader{
    width: 70vw;
    margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  }



  .sgwigly{
    width: 500px;
    z-index: 10;
    position: absolute;
  }
  .booger-characters-container {
    display: flex;
    height: 102vw;
  }
  
  .booger-character-14 {
    max-width: 60vw;
    max-height: 103vw;
    margin-right: 0px;
    margin-bottom: 45px;
}
  .revealHeader-div{
    justify-content: center;
  }
  .TextField{
    margin-top: 32px;
    margin-left: 0px;
  }

  .TextField-div {
    /* padding-top: 50px; */
  }


  .searchButton:hover {
    transform: scale(1.5);
  }
  .MuiTextField-root.text-field{
    /* margin-top: 32px; */
    margin-left: 0px;
    width: 90vw;
  }
  .character-box {
    transform: rotate(90deg);
    width: inherit;
    position: relative;
    display: block;
    overflow: initial;
    margin-top: 150px;
    margin-left: -100px;
  }
 
  .modal-connect{
    width: 90vw;
    padding: 40px 60px;
  }
  .modal-connect>div>h3{
    font-size: 28px;
  }
  

  .reveal-step-div{
    margin-top: 0px;
  }

  .booger-character-div{
    padding-top: 0px;
  }
  .booger-character-div:hover > div > .booger-character-select {
    transform: rotate(270deg);
    font-size: 16px;
    margin-left: -117px;
    padding: 2px 2px 2px 12px;
    width: 164.63px;
  }
  .booger-character-div:hover>div>.booger-character-select {
 display: none;
}
  .meme-count-mobile {
    display: block;
    margin-bottom: 10px !important;
  }

  .reveal-step{
    display: none; 
   }
   .reveal-image{
    width: 35px;
    height: 35px;
   }
   .auction-div{
    display: flex;
    justify-content: center;
  }
  .auction1-box{
    top: 400px
  }
  .auction2-box{
    top: -45px
  }
  .auction3-box{
    top: 700px
  }
  .auction4-box{
    top: 1100px;
  }
  .auction5-box{
    top: 1500px
  }
  .auction6-box{
    top: 1900px
  }
  .auction7-box{
    top: 2300px
  }
  
  .mobile-vote-header-container {
    display: block !important;
  }

  /* .vote-header-container {
    display: none !important;
  } */

  .mobile-dont-forget-box {
    position: absolute;
    left: 210px;
    top: 10px;
    transform: rotate(-30deg);
  }

  .mobile-dont-forget-box img {
    width: 200px;
  }

  .mobile-vote-select {
    position: absolute;
    top: 80px;
  }

  .mobile-vote-select img {
    width: 200px;
  }

  .vote-booger {
    top: 85px;
}

  .loser-box {
    margin-top: 220px;
    width: 100%;
  }

  .auction7-box {
    top: 2240px;
    left: 100px !important;
  }

  .vote-character-box {
    top: 2350px;
    left: 20px;
    position: absolute;
  }

  .vote-character-box .wtf-happening {
    top: 270px;
  }
  .vote-character-box > img {
    width: 150px;
  }

.total-votes-box {
    position: absolute;
    left: 0;
    margin-top: 58px;
    margin-left: 4%;
}

  .profile-table td:first-of-type {
    padding-right: 20px;
  }

  .profile-table td:nth-of-type(2n) {
    padding-left: 12px;
  }

  .profile-container {
    /* display: none !important; */
  }

  .mobile-profile-container {
    display: flex !important;
    justify-content: center;
    /* display: inline-block !important; */
    /* top: -100px; */
    position: relative;
  }

  .profile-box {
    z-index: -1;
  }

  .profile-div {
    margin-top: 70px;
  }
  .crumbs-container {
    display: flex !important;
    justify-content: center;
  }

  .rankings-box .MuiBox-root {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .notis-box .MuiBox-root {
    padding-left: 0;
    padding-right: 0;
  }

  .profile-header-table {
    position: relative;
  }

  .notis-avatar {
    width: 34px !important;
    height: 34px !important;
  }

  .notis-table table tbody tr td:nth-of-type(2) {
    text-align: right;
    padding-right: 30px;
  }

  .notis-table table tbody tr td:last-child {
    display: none;
  }

  .notis-comment {
    display: block;
    padding-left: 0 !important;
  }
}
@media (min-width:900px) and (max-width:1440px){
  .wtf-laboog-modal{
    width: 75vw;
  }
  }
  @media (min-width:900px) and (max-width:1300px){
    .dont-forget-box{
      left: -4px;
    }
    .auction6-box{
      margin-right: 64px;
    }

    }
    @media (min-width:600px) and (max-width:900px){
    .dont-forget-box img {
      width: 190px;
  }
  .vote-select img {
    width: 175px;
}
.auction-button-container {
  overflow: hidden;
  width: 315px;
  max-height: fit-content;
  height: 315px;
  border-radius: 20px;
}
}
  @media (max-width: 600px) {
    .booger-character-8 {
      max-width: 148px;
      margin-left: -43px;
  }
  .vote-booger-600px{
    display: block;
  }
  .profile-table td:first-of-type {
    padding-right: 0px;
}
  .dont-forget-box{
    left: -135px;
    width: 100px;
  }
  .loser-box{
    margin-top: 256px;
  }
  }
  @media (max-width: 400px) {
  .profile-table td:first-of-type {
    padding-right: 10px;
  }
}
/* ANIMATION */
.gradient {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #e6e6e6 8%, #f4f4f4 38%, #e6e6e6 54%);
  background-size: 1000px 640px;

  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading-spinner {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
.dragIcon{
  display: block;
  position: absolute;
  animation: linear infinite alternate;
  animation-name: run;
  animation-duration: 3s;
  height: 40px;
  
}

.search-btn {
  width: 120px !important;
}

@keyframes run {
  0% {
    right: 0;
    transform: translateX(0);
  }
  100% {
    right: 10%;
    transform: translateX(-10%);
  }
}

@media (max-width:1200px) {
  .reveal-container .middle {
    /* background-position-x: -100px !important; */
  }
}

@media (min-width: 1440px) {
  .auctions-container {
    max-width: 1440px;
  }
  
  .feed-container {
    max-width: 1440px !important;
  }
  
  .postMemes {
    left: 70px !important;
    top: 70px !important;
  }

  .postMemes img {
    width: 65%;
  }

  .pressButton {
    right: 80px;
  }



  .search-btn:hover {
    cursor: pointer !important;
  }

  .pressButton img {
    width: 65%;
  }

  .revealHeader {
    width: 65%;
  }
  
  .MuiTextField-root.text-field {
    width: 38em;
  }
}
@media (max-width:340px) {
  .navbar-twitter{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: rgb(255, 255, 255);
    padding: 0.2em 1em;
    font-size: 12px;
  }
    /* padding: 10px; */
}
@media (min-width: 3000px) {
  .dragIcon{
    display: none;
  }
}

.coc-toes-container {
  position: absolute;
  top: -63px;
  right: 160px;
}

.coc-toes-container img {
  width: 180px;
}

.chicken-shit-container {
  position: absolute;
  top: -80px;
  right: -120px;
  z-index: 3;
}

.chicken-shit-container img {
  width: 250px;
}

.cream-coc-container {
  bottom: 100px;
  left: 25%;
  z-index: 1;
}

.home-coc-toes {
  top: 0px;
  left: 30%;
}

.home-coc-toes img {
  width: 180px;
}

.home-oh-coc {
  top: 20px;
  left: 0;
}

.home-oh-coc img {
  width: 150px;
}

.home-booty-coc {
  top: 20px;
  right: 0;
}

.home-booty-coc img {
  width: 150px;
}

.home-troll-coc {
  top: 450px;
  right: 0;
}

.home-troll-coc img {
  width: 150px;
}

.claim-heart-coc {
  top: 160px;
  left: -5px;
  /* left: 30%; */
}

.claim-heart-coc img {
  transform: rotate(60deg);
  width: 180px;
}


.how-it-works {
  position: absolute;
  left: calc(100% - 520px);
  top: 200px;
  /* display: flex; */
  /* justify-content: flex-end; */
}

.how-it-works img {
  width: 400px;
}

.how-it-works:hover img {
  transform: scale(1.1);
  transition: 0.5s;
}

.search-button {
  position: absolute;
  right: -40px;
  z-index: 1000;
  top: -48px;
}

.choke-button {
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: 999;
}

.profile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.steps-container {
  text-align: left;
  /* justify-content: flex-start; */
  width: calc(100% - 290px);
  /* left: -87px; */
  position: relative;
  margin-left: -290px;
  /* background-color: #0ACC76; */
  padding: 24px 32px;
  /* box-shadow: 4px 6px #000; */
  border-radius: 20px;
  /* border: 1px solid #000; */
}

.steps-header {
  font-size: 1.1rem !important;
  /* text-shadow: 1px 2px #000 !important; */
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #FFD400; */
  color: #FFD400;
  color: black;
}

.steps-text {
  font-size: 1.675rem !important;
  text-shadow: 1px 2px #000 !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  color: #fff;
  /* color: #009251; */
}

@media (min-width: 1200px) {
  .auction-button {
    width: 90%;
  }
}

@media (max-width: 1440px) {
  .search-button {
    right: -20px;
  }
  
  .choke-button {
    right: 30px;
  }

  .home-coc-toes {
    top: 0px;
    left: 25%;
  }
  
  .home-coc-toes img {
    width: 150px;
  }
}

@media (max-width: 1300px) {
  .home-coc-toes {
    display: none;
  }
}

@media (max-width: 1150px) {
  .search-button {
    right: -20px;
    top: -26px;
  }
  
  .search-btn {
    width: 80px !important;
  }

  .choke-button {
    right: 10px;
  }
}

@media (max-width: 1100px) {
  .header3 {
    font-size: 5rem !important;
  }

  .auction-button-container {
    width: 310px;
    height: 230px;
  }

  .how-it-works {
    left: calc(100% - 360px);
    top: 140px;
  }
  .how-it-works img {
    width: 220px;
  }
  
  .coc-toes-container {
    top: -1px;
  }

  .coc-toes-container img {
    width: 120px;
  }

  .chicken-shit-container {
    top: -54px;
    right: -90px;
  }

  .chicken-shit-container img {
    width: 200px;
  }

  .cream-coc-container {
    bottom: 80px;
  }

  .home-coc-toes {
    top: 0px;
    display: block;
  }
  
  .download-text {
    visibility: visible;
  }
}

@media (max-width: 900px) {
  .auction-button {
    width: 80%;
  }
  .coc-toes-container {
    top: 38px;
  }

  .coc-toes-container img {
    width: 80px;
  }
}
@media (max-width: 600px) {
  .header3 {
    font-size: 2.5rem !important;
    text-shadow: 2px 4px #000 !important;
  }

  .how-it-works {
    left: calc(100% - 190px);
    top: 50px;
  }
  .how-it-works img {
    width: 220px;
  }

  .home-coc-toes {
    display: none;
  }

  .cream-coc-container {
    display: none;
  }

  .home-oh-coc {
    top: 50px;
  }

  .home-oh-coc img {
    width: 100px;
  }

  .home-troll-coc {
    display: none;
    top: 350px;
  }

  .home-troll-coc img {
    width: 100px;
  }

  .home-booty-coc {
    top: 100px;
  }

  .home-booty-coc img {
    width: 80px;
  }

  .claim-heart-coc {
    top: 160px;
    left: -5px;
    /* left: 30%; */
  }
  
  .claim-heart-coc img {
    transform: rotate(60deg);
    width: 100px;
  }

  .how-it-works {
    margin-top: 16px;
    justify-content: center;
  }
  .how-it-works img {
    width: 200px;
  }

  .coc-toes-container {
    display: none;
  }

  .profile-container {
    width: 90%;
  }

  .profile-img-container img {
    width: 60px !important;
  }

  .level-container {
    margin-left: 0 !important;
    margin-top: 48px;
    justify-content: center;
  }

  .chicken-shit-container {
    display: none;
  }
}

@media (max-width: 400px) {
  .header3 {
    font-size: 2.2rem !important;
    text-shadow: 1px 2px #000 !important;
  }
  .home-oh-coc {
    top: 160px;
  }

  .claim-heart-coc img {
    transform: rotate(60deg);
    width: 80px;
  }
}